
.Startup {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.Startup .modalWrapper {
    width: 100%;
    padding: 40px;
}