
.container hr {
    border: 1px solid var(--gray6-color);
    width: 100%;
}

.top {
    background-color: #f1f1f1;
    height: 50px;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
}

.top .profileImg {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    bottom: -30px;
    left: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    box-shadow: var(--box-shadow-100);
}

.top .profileInner {
    border: 2px solid #eaeaea;

    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #f1f1f1;

    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray1-color);
}

.content {
    padding: 20px;
}

.view {
    margin-top: 20px;
    padding: 20px;
    padding-bottom: 0px;

    display: flex;
    flex-direction: column;
    gap: 5px;
    user-select: none;
}

.view h2 {
    margin: 0;
    font-family: var(--font-family-paragraph);
    font-weight: 500;
    color: var(--gray1-color);
    font-size: 18px;
}

.view p {
    margin: 0;
    font-family: var(--font-family-paragraph);
    font-weight: 200;
    font-size: 12px;
    color: var(--gray3-color);
}

.edit {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    padding: 10px 0px;
}

.edit div {
    display: flex;
}

.edit p {
    margin: 0;
    font-family: var(--font-family-paragraph);
    font-weight: 400;
    font-size: 12px;
    user-select: none;
}

.edit .input {
    width: 100%;
    max-width: 250px;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
}

.rightBtns {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.btns button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelBtn {
    border: 2px solid var(--gray5-color) !important;
}