

.UseSearch {
    background-color: #fff;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-main);
    width: 100%;
}

.UseSearch.gridBased {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.UseSearch input {
    border: 0px;
    padding: 9px 12px;
    font-size: 16px;
    border-radius: 5px;
    width: 100%;
    user-select: none;
    background-color: transparent;
}

.UseSearch:focus-within {
    outline: auto;
    outline-color: var(--outline-color);
}

.UseSearch input:focus{
    outline: none;
}