

.container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    overflow-y: auto;
    padding: 5px;
    height: 100%;
}

.container hr {
    border: 1px solid var(--gray6-color);
    margin: 0px 10px;
}
