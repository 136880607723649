
.menuItems {
    height: 100%;
    padding: 0px 10px;

    display: flex;
    align-items: center;
    gap: 15px;
}

.menuItems ul {
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.menuItems li {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding-bottom: 2px;
}


.menuItems li[data-selected="true"] {
    /* text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px; */

    border-bottom: 2px solid black;
    padding-bottom: 0px;
}

.menuItems li[data-active-filter="true"] {
    color: var(--primary-color);
}

.clearFilterBtn {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clearFilterBtn[data-visible="false"] {
    display: none;
}

.menuContentContainer {
    position: absolute;
    width: 100%;
    z-index: 10;
    left: 0;
    right: 0;


    background-color: #fff;
    overflow: auto;
    transition: max-height 0.2s ease-in;
}

.menuContent {
    padding: 10px;
    display: flex;
    gap: 50px;
    border-top: 1px solid var(--border-color);
    margin-top: 10px;

    padding-bottom: 10px;
    border-bottom: 2px solid #000;

    overflow: auto;
}

.menuContent ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.menuContent li button {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-heading);

    border: 0;
    background-color: transparent;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.menuContent li button[data-selected="true"] {
    color: var(--primary-color);
}

.menuContent li button:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

.dynamicIcon {
    width: 18px;
}

.dynamicIcon svg {
    height: 18px;
    width: 18px;
}

.selectedDot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.selectedDot[data-selected="true"] {
    background-color: var(--primary-color);
}

