/** @format */

.PlanEdit {
    height: 100vh;
    background-color: var(--background-gray);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.PlanEdit .branding {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.PlanEdit .brandingImg {
    width: 100px;
}

.PlanEdit .disabledOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

    pointer-events: none;
}

.PlanEdit .title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.PlanEdit .title p {
    margin: 0;
    font-family: var(--font-family-heading);
}

.PlanEdit header {
    display: flex;
    width: 100%;
    background-color: #fff;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
}

.PlanEdit header h1 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}

.PlanEdit header .btnGroup {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    gap: 4px;
    justify-content: flex-end;
}

@media screen and (max-width: 550px) {
    .PlanEdit header h1 {
        display: none;
    }
    .PlanEdit header .btnGroup {
        justify-content: center;
    }
}

.PlanEdit header button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px 15px;
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease;
    transition: color 0.2s ease;
    border-radius: 4px;
}

.PlanEdit header button:hover {
    background-color: var(--primary-color);
    color: #fff;
}

.PlanEdit .favoriteBtnSelected {
    color: #ff0000;
}

.PlanEdit header .btnGroup button svg {
    margin-right: 3px;
}

.PlanEdit .mainGroup {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;

    margin: 10px 0px;
    width: 100%;
    max-width: 1350px;
}

.PlanEdit .leftRightGroup {
    height: calc(100% - 79px);
    width: 100%;
    gap: 10px;

    display: flex;
    justify-content: space-between;
}

.PlanEdit .leftGroup {
    height: 100%;
    width: 100%;
    max-width: 900px;
    position: relative;
}

.PlanEdit .rightGroup {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    width: 400px;
    border-left: 1px solid #cccccc;
    padding-left: 20px;
}

.PlanEdit .rightGroup h1 {
    font-size: 18px;
    margin: 0;
    font-weight: 300;
    margin-bottom: 20px;
}

.PlanEdit .UseSearch {
    border: 1px solid #cccccc;
    border-radius: 4px;
    margin-left: 0px;
}

.PlanEdit .topGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    padding: 20px 0px;
    border-bottom: 1px solid #cccccc;
}

.PlanEdit .topGroup .select {
    max-width: 400px;
    width: 100%;
}

.PlanEdit .leftRightGroup {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
}

.PlanEdit .nameInput {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 9px 12px;
    font-size: 15px;
    max-width: 400px;
    width: 100%;
}

.PlanEdit .filterGroup {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 15px;
    min-width: 300px;
    width: 100%;
    padding-right: 20px;
}

.PlanEdit .filterLeft {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.PlanEdit .filterGroup .checkBoxLabel {
    margin-left: 0px;
}

.PlanEdit .noExercisesFound {
    color: var(--gray2-color);
    font-size: 14px;
    font-family: var(--font-family-paragraph);
    text-align: center;
    margin-top: 30px;
}

.PlanEdit .dropArea {
    border: 2px dashed #ccc;
    padding: 10px;
    text-align: center;
    background-color: #f7f7f7;
    color: #777;
    position: relative;
    transition: background-color 0.3s;
}

.PlanEdit .dropElement {
    background-color: rgba(39, 174, 96, 0.2);
}

.PlanEdit .emptyText {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    margin-top: 20px;
    text-align: center;
    font-size: 16px;
}

.PlanEdit .leftGroup .smooth-dnd-container.vertical {
    gap: 9px !important;
    display: grid;
    padding-right: 10px;
    overflow-y: auto;
    max-height: calc(100% - 60px);

    grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 1000px) {
    .PlanEdit .leftGroup .smooth-dnd-container.vertical {
        grid-template-columns: repeat(2, 1fr);
    }
}

.createCustomerBtn {
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    border-radius: 5px;
    border: 0;

    font-family: var(--font-family-paragraph);
    padding: 0px 30px;
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
}
