

#AdminPanel {
    display: flex;
    flex-direction: row;
    height: 100vh;
    max-height: 100%;
}

#AdminPanel .navigation {
    height: 100vh;
    background-color: #334E68;
    width: 90vw;
    max-width: 200px;
}

#AdminPanel .navigation p {
    color: #fff
}

#AdminPanel .navigation ul {
    list-style: none;
    padding: 0px;
}

#AdminPanel .navigation li {
    background-color: #fff;
    cursor: pointer;
    padding: 12px;
}

#AdminPanel .navigation .selected {
    border-left: 9px solid var(--primary-color);
}

#AdminPanel .navigation p {
    text-align: center;
    font-family: var(--font-family-heading);
}

#AdminPanel .navigation button {
    width: 100%;
    border: 0px;
    padding: 12px;
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--primary-invert);
    font-family: var(--font-family-paragraph);
    display: flex;
    justify-content: center;
    align-items: center;
}

#AdminPanel .navigation button svg {
    font-size: 16px;
    margin-right: 5px;
}

#AdminPanel .view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    background-color: #e4e7eb;
    max-height: 100vh;
    height: 100vh;
}

.AdminListSimpleView {
    width: 89%;
    max-width: 599px;
    max-height: calc(100vh - 220px);
}

.AdminListSimpleView .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.AdminListSimpleView .info h1 {
    margin: -1px;
    font-size: 23px;
}
