/** @format */

.details {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    border: 2px solid var(--gray5-color);
    cursor: pointer;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    color: var(--gray2-color);
    padding: 12px 0px;
}

.title svg {
    width: 16px;
}

.title span {
    user-select: none;
    font-size: 14px;
    font-weight: 500;
}

.topContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.content {
    border-top: 1px solid var(--placeholder-color);
    cursor: default;
}

.summary {
    list-style: none;
    padding: 0px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: #f9fafb;
    border-radius: 3px;
}

.summary span {
    pointer-events: none;
    user-select: none;
}

.summary:focus {
    outline: none;
}

.summary .chevronUp {
    opacity: 0.5;
}

.summary:hover .chevronUp,
.summary:hover .chevronDown {
    opacity: 1;
}

.chevronUp,
.chevronDown {
    user-select: none;
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.summary::-webkit-details-marker {
    display: none;
    user-select: none;
    pointer-events: none;
}
