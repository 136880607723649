
.borderInput {
    border: 1px solid var(--input-border-color);
    background-color: #fff;
    border-radius: var(--border-radius-main);
    padding: 9px 12px;
    font-size: 16px;
}

.borderInputIcon {
    border: 1px solid var(--input-border-color);
    background-color: #fff;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-main);
}

.borderInputIcon input {
    border: 0px;
    padding: 9px 12px;
    padding-left: 6px;
    font-size: 12px;
    font-family: var(--font-family-paragraph);
    font-weight: 400;
    border-radius: 5px;
    width: 100%;
    user-select: none;
    background-color: transparent;
}

.borderInputIcon svg {
    color: var(--gray2-color);
    width: 20px;
}

.borderInputIcon:focus-within {
    outline: auto;
    outline-color: var(--outline-color);
}

.borderInputIcon input:focus{
    outline: none;
}
