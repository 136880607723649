
.inputSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 20px 15px;
}


.inputSection hr {
    border: 1px solid var(--gray6-color);
    margin: 0px 10px;
}

.inputSection .infoText {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    user-select: none;
    max-width: 200px;
}

.inputSection h2 {
    font-size: 12px;
    font-family: var(--font-family-heading);
    font-weight: 500;
    margin: 0;
}

.inputSection p {
    margin: 0;
    font-family: var(--font-family-paragraph);
    font-weight: 200;
    font-size: 12px;
    color: var(--gray3-color);
}

.input {
    width: 100%;
    max-width: 250px;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.actionBtn {
    cursor: pointer;
    border: 0;
    background-color: transparent;
    padding: 5px 12px;
}

.saveBtn {
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 5px;
}

.settingsBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 25px;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #fff;
    border: 0;
    font-size: 14px;
    cursor: pointer;
}

.deleteUserBtn {
    background-color: #e64545;
    color: #fbe1e1;
}