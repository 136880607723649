

.header {
    width: 100%;
    height: 80px;
    background-color: #fff;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
}

.title {
    margin: 0;
    font-family: var(--font-family-poppins);
    font-weight: 500;
    font-size: 22px;
    user-select: none;
}

.profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.profile img {
    width: 100%;
    max-width: 40px;

    height: auto;
    max-height: 50px;
}

.profile h3 {
    margin: 0;
    font-family: var(--font-family-poppins);
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
}

.profile p {
    margin: 0;
    color: var(--gray4-color);
    font-family: var(--font-family-poppins);
    font-size: 12px;
    white-space: nowrap;
}

