
.PlanShare {
    text-align: right;
    height: 100%;

    padding: 12px;
    display: flex;
    flex-direction: column;
}

.PlanShare .inputGroup {
    margin-bottom: 0px;
}

.PlanShare form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.PlanShare form .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.PlanShare form textarea {
    width: 100%;
    resize: none;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 9px 12px;
    font-size: 15px;
    height: 80px;
}

.PlanShare iframe {
    margin-top: 20px;
    border: 0;
    width: 100%;
    height: 100%;
}