
.container {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 7px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    user-select: none;
}

.container button {
    cursor: pointer;
    background-color: transparent;
    border-radius: 10px;
    border: 0;
    padding: 6px 12px;
    font-family: var(--font-family-poppins);
    color: var(--gray3-color);
    font-size: 14px;
    white-space: nowrap;
}

.container button:hover {
    background-color: #efefef;
}

.container button[data-selected="true"] {
    box-shadow: var(--box-shadow-50);
    background-color: #fff !important;
    color: #000;
    font-weight: 500;
}