
.Button {
    border: 0px;
    padding: 12px 30px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
}

.Button.Primary {
    background-color: var(--primary-color);
    color: var(--primary-invert);
}

.Button.Secondary {
    background-color: transparent;
    color: #000;
    box-shadow: none;
    font-size: 14px;
}

.Button.Rounded {
    border-radius: 50px;
}

.Button.Small {
    padding: 8px 20px;
}

.Button.NoShadow {
    box-shadow: none;
}

.Button.Centered {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Button.NoPadding {
    padding: 0;
}

Button.nostyle {
    margin: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    border: 0;
}

.favoritesBtn {
    white-space: nowrap;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px;
    border-radius: var(--border-radius-main);
    border: 1px solid var(--input-border-color);
    padding: 0px 20px;
    color: var(--gray2-color);
    background-color: transparent;
    font-size: 14px;
    user-select: none;
}

.favoritesBtnSelected {
    border: 1px solid var(--favorite-color) !important;
}