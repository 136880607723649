
.container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.filtering {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.searchBox {
    border: 1px solid var(--input-border-color);
}

.ordering {
    display: flex;
}
