
.PaymentValidation {
    display: flex;
    flex-direction: column;
    
}

.PaymentValidation h1 {
    font-family: var(--font-family-heading);
    margin-bottom: 0;
}

.PaymentValidation p {
    font-family: var(--font-family-paragraph);
}

.PaymentValidation .btns {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}