

#LoginSignup {
    background-color: #D8D8D8;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

#LoginSignup .section {
    width: 50%;
}

#LoginSignup .viewSection {
    padding: 20px 40px;
}

#LoginSignup h1 {
    font-size: 28px;
    font-family: var(--font-family-heading);
    user-select: none;
}

#LoginSignup .welcomeText {
    margin: 0px 0px 25px 0px;
    font-family: var(--font-family-paragraph);
    font-weight: 300;
    user-select: none;
}

#LoginSignup a {
    color: #647ACB;
    text-decoration: none;
    user-select: none;
}

#LoginSignup .modal {
    max-width: 800px;
    width: 90vw;
    background-color: #fff;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    box-shadow: var(--box-shadow-200);
}

#LoginSignup .btnGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 35px 0px 60px 0px;
}

#LoginSignup .btnGroup a {
    font-size: 15px;
}

#LoginSignup .btnGroup input {
    background-color: var(--primary-color);
    color: var(--primary-invert);
    border: 0px;
    padding: 12px 30px;
    font-size: 16px;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: var(--box-shadow-200);
}

#LoginSignup .imageSection {
    position: relative;
    text-align: center;
    color: #fff;
}

#LoginSignup .imageSection img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
}

#LoginSignup .imageSection .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    background-color: rgba(0,0,0,0.4);
}

#LoginSignup .imageSection .overlay img {
    margin-bottom: 50px;
    width: 200px;
    height: auto;
    object-fit: contain;
}


#LoginSignup .imageSection h2 {
    font-family: var(--font-family-heading);
    margin: 0px;
}

#LoginSignup .imageSection p {
    font-family: var(--font-family-paragraph);
    word-wrap: break-word;
    max-width: 80%;
}


@media screen and (max-width: 700px) {
    #LoginSignup .imageSection {
        display: none;
    }

    #LoginSignup .viewSection {
        width: 100% !important;
    }
}
