
.wrapper {
    z-index: 15;
    background-color: rgba(0,0,0,0.4);

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 30px;
    user-select: none;
}

.modal {
    user-select: auto;
    background-color: #fff;
    border-radius: 20px;
    padding: 3px;

    display: flex;
    flex-direction: row;
    gap: 8px;

    box-shadow: var(--box-shadow-50);
}

.section {
    width: 100dvw;
    height: 100dvh;
    max-width: 400px;
    max-height: 380px;

    display: flex;
    flex-direction: column;

    border: 2px solid var(--gray6-color);
    border-radius: 17px;
}

@media screen and (max-width: 850px) {
    .modal {
        flex-direction: column;
    }
}

.planListSection {
    background-color: var(--gray7-color);
}