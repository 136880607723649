
.UseModal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    max-height: 100%;
    max-width: 100%;
    background: rgba(0,0,0, .8);
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 20px;
}

.UseModal .innerModal {
    background-color: #fff;
    width: 100%;
    height: 100%;
    max-width: 1100px;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    border: 0;
    border-radius: var(--border-radius-main);
    overflow-y: auto;
    z-index: 50;
}

.UseModal .innerModal .modalHeader {
    border-top-left-radius: var(--border-radius-main);
    border-top-right-radius: var(--border-radius-main);
    width: 100%;
    padding: 12px 15px;
    height: 50px;

    color: #838383;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.UseModal .innerModal .modalHeader p {
    font-weight: 200;
    user-select: none;
}

.UseModal .innerModal .modalHeader svg {
    color: #838383;
}


.UseModal .innerModal .modalHeader button {
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    font-size: 26px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
}

.UseModal .innerModal .modalHeader p {
    padding: 0px;
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-family-heading);
}

.UseModal .modalWrapper {
    width: 100%;
    height: 100%;
    max-height: calc(100% - 55px);
}