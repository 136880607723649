

.SharedList .contextMenu {
    position: absolute; 
    box-shadow: var(--box-shadow-300);
    background-color: #fff;
    border-radius: 5px;
    min-width: 250px;
}

.SharedList .contextMenu ul {
    list-style: none;
    padding: 0px;
    margin: 7px 0px;
}

.SharedList .contextMenu li {
    padding: 8px 20px;
    cursor: pointer;
    transition: 0.1s linear;
    text-align: left;
}

.SharedList .contextMenu li:hover {
    background-color: #f1f3f7;
}

.SharedList .contextMenu li p {
    margin: 0px;
}

