

.SingleUser .btnGroup {
    margin-top: 20px;
}

.SingleUser .mainContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.SingleUser .mainContent p {
    font-size: 12px;
    margin: 0px;
}

.SingleUser .mainContent .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SingleUser .mainContent .right {
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.SingleUser .followup {
    border-right: 3px solid var(--warning-color);
}

.SingleUser .specialUser {
    border-right: 3px solid var(--info-color);
}