/** @format */

.Payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Payment .info {
    width: 100%;
    max-width: 800px;
    margin-bottom: 30px;
}

.Payment .info h1 {
    font-size: 24px; 
    font-weight: 400;
    font-family: var(--font-family-heading);
}

.Payment .info p {
    font-size: 14px;
    font-family: var(--font-family-paragraph);
}

.Payment .btns {
    text-align: center;
}

.Payment .btns button {
    cursor: pointer;
    border: 0;
    padding: 8px 14px;
    font-size: 14px;
    background-color: #fff;

    border: 1px solid #cbd2d9;

    color: #232323;
    user-select: none;
    box-shadow: 0;
}

.Payment .btns .selected {
    color: #fff;
    background-color: var(--primary-color);
}

.Payment .product {
    margin: 30px 0px;
    width: 100%;
    max-width: 300px;
    height: 240px;
    box-shadow: var(--box-shadow-300);
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Payment .product h1 {
    font-weight: 400;
    font-size: 18px;
    user-select: none;
    font-family: var(--font-family-heading);
}

.Payment .product .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.Payment .product .counter h2 {
    margin: 0px;
}

.Payment .product .counter .Button.Secondary {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Payment .PayNowBtn {
    max-width: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Payment .cta {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}