
.CrudList {
    height: 100%;
    width: 100%;
}

.CrudList .searchBox {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}

.CrudList .searchBox div {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.CrudList .searchBox button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.CrudList form .ListItem {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    padding-right: 0px;
}

.CrudList form div {
    width: 100%;
}

.CrudList form .btnGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 12px;
}

.CrudList form input {
    box-shadow: none;
}
