

.Home {
    background-color: var(--background-gray);
    width: 100dvw;
    height: 100dvh;
    max-height: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: row;
}

.rightside {
    height: 100%;
    width: 100%;
    max-width: calc(100vw - 240px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentWrapper {
    height: calc(100% - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 40px 0px 40px;
}

.content {
    height: 100%;
    background-color: #fff;
    width: 100%;
    max-width: 900px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    padding: 30px 50px;
    padding-bottom: 5px;
}