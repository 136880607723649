
.CustomPayment {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.CustomPayment .infoBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.CustomPayment h1 {
    font-family: var(--font-family-heading);
    font-weight: 300;
    font-size: 20px;
    margin: 0;
}

.CustomPayment h2 {
    text-align: center;
    font-family: var(--font-family-heading);
    font-weight: 400;
    font-size: 24px;
    margin: 0;
}

.CustomPayment .priceBox {
    border: 1px solid var(--gray5-color);
    border-radius: 5px;
    padding: 10px;
    position: relative;
}

.CustomPayment .priceBox h3 {
    position: absolute;
    top: -22px;
    background-color: #fff;
    padding: 0px 5px;
    user-select: none;


    color: var(--gray5-color);
    font-size: 14px;
    font-family: var(--font-family-paragraph);
    font-weight: 300;
}

.CustomPayment .priceBox p {
    font-size: 14px;
    font-family: var(--font-family-paragraph);
    font-weight: 200;
}

.CustomPayment .cta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}