.customerItem {
    display: flex;
    flex-direction: column;
    padding: 15px 20px 15px 20px;
    box-shadow: var(--box-shadow-100);
}

.customerItem h2 {
    margin: 0px;
    font-weight: 400;
    font-size: 18px;
}

.customerItem h3 {
    margin: 0;
    margin-top: 0px;
    font-size: 16px;
    font-weight: 200;
    color: #BDBDBD;
}

.customerItem .selected {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.customerItem button {
    cursor: pointer;
    padding: 8px 15px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    border-radius: 5px;
    border: 0;
    background-color: transparent;
}

.customerItem button:hover {
    background-color: var(--primary-color);
}