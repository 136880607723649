
.inputGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--border-color);
    border-bottom: 1px solid;
    margin-bottom: 24px;
    padding-left: 4px;
    padding-right: 12px;
    background-color: transparent;
}

.inputGroup svg {
    font-size: 18px;
}

.inputGroup input {
    border: 0;
    padding: 9px 4px;
    font-size: 16px;
    width: 100%;
    background-color: transparent;
}

.inputGroup:focus-within {
    color: #000;
}

.inputGroup input:focus{
    outline: none;
}

.inputGroup input:-webkit-autofill,
.inputGroup input:-webkit-autofill:hover,
.inputGroup input:-webkit-autofill:focus,
.inputGroup input:-webkit-autofill:active {
    background-color: transparent !important;
}

.inputGroup.mini {
    padding-left: 2px;
    padding-right: 8px;
}

.inputGroup.mini input {
    padding: 6px 2px;
    font-size: 14px;
}


.checkBoxLabel {
    white-space: nowrap;
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.checkBoxLabel input {
    width: 20px;
    height: 20px;
    cursor: pointer;
}