


.ImageUpload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f2f5f7;
    border: 2px solid #7a909e;
    border-style: dashed;
    border-radius: var(--border-radius-main);

    position: relative;

    width: 100%;
    max-width: 260px;
    height: 100dvh;
    max-height: 70px;
}

.ImageUpload .uploadLabelBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    font-family: var(--font-family-paragraph);
    font-weight: 400;

    width: 100%;
    height: 100%;
}

.ImageUpload .uploadInput {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    opacity: 0;
}

.ImageUpload .image {
    margin: 10px 0px;
    width: 100%;
    max-width: 100px;

    height: auto;
    max-height: 60px;

    object-fit: contain;
}

.ImageUpload .removeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    color: var(--error-color);
}

