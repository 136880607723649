
.PlanPrint {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
}

.PlanPrint p {
    margin: 0px;
    margin-bottom: 12px;
    font-size: 18px;
}

.PlanPrint form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.PlanPrint .select {
    width: 100%;
}

.PlanPrint form .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.PlanPrint form textarea {
    width: 100%;
    resize: none;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 9px 12px;
    font-size: 15px;
    height: 80px;
}

.PlanPrint iframe {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    border: 0;
}
