
.fullScreenLoading {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    max-width: 100%;
    height: 100vh;
    max-height: 100%;
}