
.container {
    max-height: 100%;
    padding-right: 10px;
    padding-bottom: 10px;
    overflow-y: scroll;
}

.noPlansContainer {
    display: flex;
    justify-content: center;
}

.noPlansContainer p {
    font-family: var(--font-family-paragraph);
    font-weight: 200;
    font-size: 16px;
    color: var(--gray3-color);
    user-select: none;
    text-align: center;
    margin-top: 30px;
    max-width: 500px;
}

.container h2, .container p {
    padding: 0;
    margin: 0;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
}

.leftSide {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.container ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.listBtn {
    display: flex;
    flex-direction: row;
    align-items: center;

    min-width: 100%;

    border: 2px solid var(--gray5-color);
    background-color: transparent;
    border-radius: 15px;
    padding: 15px 30px;
    cursor: pointer;
    position: relative;
    gap: 20px;
}

.listBtn:hover {
    box-shadow: var(--box-shadow-100);
    border-color: var(--gray4-color);
}

.favoriteBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0px;
    margin: 0px;
    font-size: 18px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: var(--gray3-color);

    display: flex;
    justify-content: center;
    align-items: center;
}

.favoriteBtnSelected {
    color: var(--favorite-color);
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    border-right: 2px solid var(--gray5-color);
    padding-right: 30px;
    color: var(--gray2-color);
    position: relative;
}

.container .countText {
    position: absolute;
    top: -2px;
    right: 18px;

    background-color: var(--gray2-color);
    font-weight: 600;
    color: #fff;
    border-radius: 50%;
    padding: 3px 5px;
    font-size: 9px;

    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.primaryText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
}

.primaryText h2 {
    font-family: var(--font-family-heading);
    font-size: 14px;
    font-weight: 400;

    display: flex;
    flex-direction: row;
    gap: 7px;
}

.primaryText p {
    font-size: 12px;
    font-weight: 200;
    color: var(--gray4-color);
    width: 100%;
    max-width: 250px;
    text-align: left;
}

.rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.secondaryText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 10px;
}

.secondaryText p {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 5px;
    font-size: 12px;
    color: var(--gray3-color);
}

.secondaryText svg {
    width: 12px;
}


