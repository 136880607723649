
.sidebar {
    width: 240px;
    height: 100%;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bottomSection {
    margin-bottom: 20px;
}

.branding {
    display: block;
    margin-top: 10px;
    padding: 20px 30px;
    text-align: center;
    user-select: none;
}

.brandingImg {
    width: 100%;
}

.sideBtn {
    cursor: pointer;
    width: 100%;

    font-family: var(--font-family-poppins);
    font-weight: 400;
    font-size: 20px;

    display: flex;
    align-items: center;
    gap: 8px;

    color: #737791;
    background-color: transparent;
    border: 0;

    padding: 15px;
    user-select: none;
}

.sideBtn:hover {
    background-color: rgba(var(--primary-color-rgb), 0.15);
}

.sideBtn[data-selected="true"] {
    background-color: rgba(var(--primary-color-rgb), 0.15);
    color: var(--primary-color);
    font-weight: 500;
    border-right: 6px solid var(--primary-color);
}

.createSection {
    border-bottom: 3px solid var(--gray5-color);
    padding-bottom: 10px;
}

.createPlanBtn {
    font-weight: 500;
    color: var(--success-color);
}

.createPlanBtn:hover {
    font-weight: 500;
    color: #737791;
}

.buttonSection {
    margin-top: 10px;
}

