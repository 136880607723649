/** @format */

.ListItem {
    margin-bottom: 12px;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    padding: 20px 5px 20px 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ListItem .componentHolder {
    padding-right: 20px;
}

.ListItem h2 {
    font-family: var(--font-family-heading);
    font-weight: 400;
    font-size: 18px;
    margin: 0px;
    margin-bottom: 5px;
}

.ListItem button {
    border: 0px;
    background-color: transparent;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ListItem p {
    margin: 0px;
}

.ListItem .leftSide {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.ListItem .rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ListItem .rightSide div {
    padding-right: 16px;
}

@media screen and (max-width: 1100px) {
    .ListItem .rightSide div {
        padding-right: 0px;
    }
}
