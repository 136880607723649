

.container {
    background-color: black;
    width: 100%;
    height: 100%;
    padding-top: 10px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}


.container h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
}

.container input {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
}

.container textarea {
    height: 100%;
    resize: none;
    background-color: transparent;
    color: #fff;
    border: 0;
    font-size: 20px;
    font-weight: 100;
    font-family: monospace;
    letter-spacing: 2px;
    padding: 0px 20px;
    user-select: none;
    pointer-events: none;
}
