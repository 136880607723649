

.listFramework {
    height: 100%;
    max-height: 100%;
}

.listFramework ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.listFramework li {
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    transition: height .2s ease;
}

.listFramework .elementBase {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.listFramework .buttonBase {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

