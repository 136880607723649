

.container {
    width: 100%;
    height: 100%;
    padding: 20px 12px;
    padding-bottom: 4px;

    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
}

.closeBtn {
    position: absolute;
    top: 7px;
    right: 7px;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    color: var(--gray3-color);
    border: 2px solid var(--gray5-color);
    background-color: transparent;
    padding: 5px;
}

.container h2 {
    font-size: 18px;
    font-family: var(--font-family-paragraph);
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
    color: var(--gray2-color);
    user-select: none;
}

.searchBox {
    width: 100%;
}

.filtering {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.createBtn {
    white-space: nowrap;
    background-color: #1f1f21 !important;
    color: #fff !important;
    border: 1px solid var(--input-border-color);
    border-radius: 5px;
    font-size: 12px !important;
}

.noPlans {
    display: flex;
    justify-content: center;
    user-select: none;
}

.noPlans p {
    color: var(--gray3-color);
}

.container ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-y: scroll;
    padding: 2px;
}

.listBtn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 12px;
    border: 0;
    cursor: pointer;
    background-color: transparent;
    border-radius: 10px;
    color: var(--gray2-color);
}

.listBtn .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.listBtn p {
    font-family: var(--font-family-poppins);
    font-size: 12px;
    font-weight: 400;
}

.favBtn {
    color: var(--gray2-color);
    font-size: 18px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}