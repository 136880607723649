/** @format */

.AddedExercise {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    margin-bottom: 20px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
}

.AddedExercise .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.2s;
}

.AddedExercise .closeBtn:hover {
    background-color: var(--error-color);
}

.AddedExercise .header {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
}

.AddedExercise h2 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    max-width: 100%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.AddedExercise .header button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AddedExercise .content {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.AddedExercise .img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.AddedExercise .copyInfoBtn {
    border: 0;
    background-color: transparent;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
    color: #069;
    border-radius: 3px;
    font-size: 12px;
}

.AddedExercise .copyInfoBtn:hover {
    font-weight: 600;
}

.AddedExercise .textboxes {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.AddedExercise img,
.AddedExercise video {
    user-select: none;
    max-width: 150px;
    width: 100%;
}

.AddedExercise .inputGroup {
    margin-bottom: 0px;
}
