
.CreateExercise {
    padding: 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.CreateExercise form {
  max-width: 600px;
  width: 100%;
}

.CreateExercise .selectWrapper {
  width: 100%;
  margin-bottom: 30px;
}

.CreateExercise textarea {
  resize: none;
}

.CreateExercise textarea {
    width: 100%;
    min-height: 200px;
    font-size: 14px;
    padding: 10px;
    border-color: var(--border-color);
    border-radius: var(--border-radius-main);
}

.CreateExercise .fileSelector {
    width: 100%;
    padding: 20px;
    padding-left: 30px;
    border: 1px solid var(--border-color);
    margin-top: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.CreateExercise .fileSelector p {
    margin: 0px;
    margin-bottom: 12px;
    font-size: 14px;
}

.CreateExercise .fileSelector ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.CreateExercise .fileSelector li {
  border-radius: var(--border-radius-main);
  padding: 9px 14px;
  background-color: var(--primary-color);
  margin-bottom: 5px;
  color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
}


.CreateExercise .fileSelector li p {
  margin: 0;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CreateExercise .fileSelector li select {
  margin: 0px 10px;
}

.CreateExercise .fileSelector li .selects {
  display: flex;
  flex-direction: row;
}

.CreateExercise .fileSelector li button {
  color: #fff;
  background-color: transparent;
  border: 0;
  font-size: 18px;
  padding: 0;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.CreateExercise .fileSelector input::-webkit-file-upload-button {
  visibility: hidden;
}
.CreateExercise .fileSelector input::before {
  content: 'Dra filer inn, eller trykk for å laste opp';
  display: block;
  border: 2px solid #7a909e;
  border-style: dashed;
  border-radius: var(--border-radius-main);
  background-color: #f2f5f7;
  color: #000;
  padding: 30px 18px;
  outline: none;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
}
.CreateExercise .fileSelector input:hover::before {
  border-color: black;
}

.CreateExercise .fileSelector input {
  color: transparent;
}

.CreateExercise .submitWrapper {
    width: 100%;
    text-align: right;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}