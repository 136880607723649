
.SharedList .mainList {
    list-style: none;
    padding: 0px;
    margin: 0px;
    overflow-y: auto;
    max-height: 100vh;
}

.SharedList .mainList.ulGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    column-gap: 12px;
}

.SharedList .liGrid {
    max-width: 400px;
}

.SharedList .dragHelper {
    width: 90px;
    height: 4px;
    border-radius: var(--border-radius-main);
    background-color: var(--primary-color);
    position: absolute;
    display: none;
}

.SharedList .dropZone {
    height: 12px;
}

.SharedList .topDrop {
    user-select: none;
    font-family: var(--font-family-heading);
    font-weight: 200;
}