
.typeBadge {
    position: fixed;
    z-index: 20;
    background-color: #D74E9A;
    color: #fff;
    bottom: 10px;
    right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    pointer-events: none;
}

.typeBadge p {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-family: var(--font-family-heading);
    letter-spacing: 1px;
    text-transform: lowercase;
}