

.PlanExerciseEdit {
    height: 100%;
    overflow-y: auto;
}


.PlanExerciseEdit hr {
    margin: 0;
}

.PlanExerciseEdit .miniInputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;    
    padding: 20px;
}

.PlanExerciseEdit .miniInputs .inputGroup {
    margin: 0;
    width: 100%;
    max-width: 200px;
}

.PlanExerciseEdit .mainInputs {
    padding: 0px 20px;
}

.PlanExerciseEdit .commentBox {
    position: relative;
    height: 120px;
}

.PlanExerciseEdit .commentBox button {
    position: absolute;
    top: 8px;
    right: 8px;
}

.PlanExerciseEdit .commentBox textarea {
    width: 100% !important;
    padding: 10px;
    padding-right: 200px;
    height: 100%;
    position: absolute;
    resize: none;
    border-color: var(--border-color);
    border-radius: 5px;
}

.PlanExerciseEdit .commentBox textarea:focus {
    border-color: #000;
    outline: none;
}

.PlanExerciseEdit .commentBox textarea:-webkit-autofill,
.PlanExerciseEdit .commentBox textarea:-webkit-autofill:hover,
.PlanExerciseEdit .commentBox textarea:-webkit-autofill:focus,
.PlanExerciseEdit .commentBox textarea:-webkit-autofill:active {
    background-color: transparent !important;
}

.PlanExerciseEdit .files {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.PlanExerciseEdit .files img, .PlanExerciseEdit .files video {
    max-width: 600px;
    width: 100%;
}
