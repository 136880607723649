body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Fira Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline-color: var(--outline-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 5px;
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.previewIframe {
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: none;
}

input:disabled, input[disabled=disabled] {
    color: #D9E2EC !important;
    cursor: not-allowed !important;
}

button:disabled, button[disabled=disabled] {
    color: #D9E2EC !important;
    cursor: not-allowed !important;
}

video::-internal-media-controls-overlay-cast-button {
    display: none;
}
