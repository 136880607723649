


.ValidateEmail h1 {
    font-size: 26px;
    font-weight: 200;
    font-family: var(--font-family-heading);
    margin: 0
}

.ValidateEmail p {
    font-size: 16px;
    font-family: var(--font-family-paragraph);
}

.ValidateEmail form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.ValidateEmail .inputGroup {
    margin-right: 30px;
    max-width: 250px;
    margin-bottom: 0;
}

.ValidateEmail .codeInput {
    font-size: 20px;
    -moz-appearance:textfield;
}

.ValidateEmail .codeInput::-webkit-outer-spin-button,
.ValidateEmail .codeInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}



