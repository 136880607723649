/** @format */

.PlanView {
    width: 100%;

    border: 2px solid #e2e8f0;
    border-radius: 5px;

    transition: border-color 0.2s ease;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-basis: 33.33333%;
}

.PlanView:hover {
    border-color: var(--info-color);
    border-width: 3px;
}

.elementDrop {
    border: 1px solid var(--success-color);
    background-color: green;
}

.PlanView .floatingBtn {
    position: absolute;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 22px;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    color: #000;
}

.PlanView .floatingBtn:focus {
    outline: none;
}

.PlanView .heartBtn {
    top: 0;
}

.PlanView .heartBtn.like {
    color: red;
}

.PlanView .infoBtn {
    top: 25px;
}

.PlanView .textBox {
    width: 100%;
    padding: 13px 8px;
}

.PlanView h2 {
    font-size: 14px;
    font-weight: 500;

    margin: 0px;
    text-align: center;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.PlanView .contentBox {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
    background-color: #dfd6d6;

    /* To get inner border radius: border-radius - border-width */
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.PlanView img,
.PlanView video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    object-fit: contain;
    user-select: none;
    pointer-events: none;

    border: 0;

    /* To get inner border radius: border-radius - border-width */
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
