
.SingleTag .titleDiv {
    display: flex;
    align-items: center;
    gap: 10px;
}

.SingleTag .iconDiv svg {
    width: 14px;
    height: 14px;
}


.SingleTagMoveModal {
    padding: 20px;
}

.SingleTagMoveModal h1 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 5px;
}

.SingleTagMoveModal p {
    margin: 0;
    font-size: 14px;
    margin-bottom: 15px;
}


.SingleTagMoveModal .cmd {
    margin: 10px 0px;
}

.SingleTagMoveModal .cmd p {
    margin-bottom: 0;
}

.SingleTagMoveModal .btns {
    display: flex;
    gap: 5px;
    justify-content: end;
    margin-top: 15px;
}

.SingleTagMoveModal .react-select__menu {
    z-index: 1000; /* Ensure it's above other elements */
}

.SingleTagMoveModal .react-select__control {
    overflow: visible; /* Prevent cutting off dropdown */
}