
:root {
  /* Box Shadow */
  --box-shadow-50: 0 1px 2px hsla(0, 0%, 0%, 0.1);
  --box-shadow-100: 0 1px 3px hsla(0, 0%, 0%, 0.2);
  --box-shadow-200: 0 4px 6px hsla(0, 0%, 0%, 0.2);
  --box-shadow-300: 0 5px 15px hsla(0, 0%, 0%, 0.2);
  --box-shadow-400: 0 10px 24px hsla(0, 0%, 0%, 0.2);
  --box-shadow-500: 0 15px 35px hsla(0, 0%, 0%, 0.2);

  /* Font */
  --font-family-heading: 'Roboto', sans-serif;
  --font-family-paragraph: 'Fira Sans', sans-serif;
  --font-family-poppins: 'Poppins', sans-serif;

  --background-blur: rgba(0, 0, 0, 0.5);
  --background-gray: #e4e7eb;

  --primary-invert: #fff;


  --border-radius-main: 5px;
  --border-color: #D8D8D8;
  --input-border-color: #CCCCCC;

  --primary-color: #31bfc9;
  --primary-color-rgb: 49, 191, 201;

  --secondary-color: #334e68;
  --secondary-color-rgb: 51, 78, 104;

  --info-color: #2f80ed;
  --success-color: #27ae60;
  --warning-color: #e2b93b;
  --error-color: #eb5757;
  --favorite-color: #ff0000;

  --outline-color: #2684FF;

  --gray1-color: #333333;
  --gray2-color: #4F4F4F;
  --gray3-color: #828282;
  --gray4-color: #BDBDBD;
  --gray5-color: #E0E0E0;
  --gray6-color: #ededed;
  --gray7-color: #fafafa;
}