

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 15px;
}

.filtering {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
}

.searchBox {
    border: 1px solid var(--input-border-color);
}

.createBtn {
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    border-radius: 5px;
    border: 0;

    font-family: var(--font-family-paragraph);
    padding: 0px 30px;
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
}
